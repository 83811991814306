import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/service/guard/auth-guard.service';
import { AutologinGuard } from './modules/service/guard/autologin-guard.guard';
import { GoogleMapsGuard } from './modules/service/guard/google-maps.guard';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { ThankyouComponent } from './shared/thankyou/thankyou.component';
import { SigninComponent } from './components/signin/signin.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './shared/error/error.component';

const routes: Routes = [
    {
        path: 'autologin/:token/hiddenHeader', canActivate: [AutologinGuard], component: SigninComponent
    },
    {
        path: 'autologin/:token', canActivate: [AutologinGuard], component: SigninComponent
    },
    {
        path: 'signin', component: SigninComponent
    },
    {
        path: 'password-recovery/:token', component: PasswordRecoveryComponent
    },
    {
        path: 'thankyou', component: ThankyouComponent
    },
    {
        path: 'error/:message', component: ErrorComponent
    },
    {
        path: 'sharelinkview/:token', loadChildren: () => import('./modules/sharelinkview/sharelinkview.module').then(m => m.SharelinkviewModule), data: { noNavigation: true }
    },
    {
        path: 'map', canActivate: [AuthGuard], canLoad: [GoogleMapsGuard], loadChildren: () => import('./modules/map/map.module').then(m => m.MapModule), data: { title: 'Map.pageTitle.empty' }
    },
    {
        path: 'cts', canActivate: [AuthGuard], loadChildren: () => import('./modules/cts/cts.module').then(m => m.CtsModule), data: { title: 'Map.pageTitle.empty' }
    },
    {
        path: 'tt2', canActivate: [AuthGuard], loadChildren: () => import('./modules/tt2/tt2.module').then(m => m.Tt2Module), data: { title: 'Map.pageTitle.empty' }
    },
    {
        path: 'tms', canActivate: [AuthGuard], loadChildren: () => import('./modules/tms/tms.module').then(m => m.TmsModule)
    },
    {
        path: 'tds', canActivate: [AuthGuard], canLoad: [GoogleMapsGuard], loadChildren: () => import('./modules/tds/tds.module').then(m => m.TdsModule)
    },
    {
        path: 'pgs', canActivate: [AuthGuard], canLoad: [GoogleMapsGuard], loadChildren: () => import('./modules/pgs/pgs.module').then(m => m.PgsModule)
    },
    {
        path: 'route', canActivate: [AuthGuard], canLoad: [GoogleMapsGuard], loadChildren: () => import('./modules/route/route.module').then(m => m.RouteModule), data: { title: 'Route.pageTitle.empty' }
    },
    {
        path: 'notification', canActivate: [AuthGuard], loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule)
    },
    {
        path: 'tacho', canActivate: [AuthGuard], loadChildren: () => import('./modules/tacho/tacho.module').then(m => m.TachoModule)
    },
    {
        path: 'reports', canActivate: [AuthGuard], loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)
    },
    {
        path: 'roadtax', canActivate: [AuthGuard], loadChildren: () => import('./modules/roadtax/roadtax.module').then(m => m.RoadtaxModule)
    },
    {
        path: 'accounting', canActivate: [AuthGuard], loadChildren: () => import('./modules/accounting/accounting.module').then(m => m.AccountingModule)
    },
    {
        path: 'sharelink', canActivate: [AuthGuard], loadChildren: () => import('./modules/sharelink/sharelink.module').then(m => m.SharelinkModule)
    },
    {
        path: 'dispatcher', canActivate: [AuthGuard], loadChildren: () => import('./modules/dispatcher/dispatcher.module').then(m => m.DispatcherModule), data: { title: 'Dispatcher.pageTitle.empty' }
    },
    {
        path: 'documents', canActivate: [AuthGuard], loadChildren: () => import('./modules/document/document.module').then(m => m.DocumentModule)
    },
    {
        path: 'suspended', canActivate: [AuthGuard], loadChildren: () => import('./modules/suspended/suspended.module').then(m => m.SuspendedModule)
    },
    {
        path: 'drivers', canActivate: [AuthGuard], loadChildren: () => import('./modules/driver/driver.module').then(m => m.DriverModule)
    },
    {
        path: 'users', canActivate: [AuthGuard], loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
    },
    {
        path: 'packages', canActivate: [AuthGuard], loadChildren: () => import('./modules/packages/packages.module').then(m => m.PackagesModule)
    },
    {
        path: 'settings', canActivate: [AuthGuard], loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'rer', canActivate: [AuthGuard], loadChildren: () => import('./modules/rer/rer.module').then(m => m.RerModule)
    },
    {
        path: 'contacts-agenda', canActivate: [AuthGuard], loadChildren: () => import('./modules/contacts-agenda/contacts-agenda.module').then(m => m.ContactsAgendaModule)
    },
    {
        path: 'e-transport', canActivate: [AuthGuard], loadChildren: () => import('./modules/e-transport/e-transport.module').then(m => m.ETransportModule)
    },
    {
        path: '', canActivate: [AuthGuard], component: HomeComponent, pathMatch: 'full'
    },
    {
        path: '**', redirectTo: ''
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
